<template>
  <div v-if="currentUser.superAdmin">
    <v-data-table
      :headers="headers"
      :items="sectors"
      :options.sync="pagination"
      :server-items-length="totalSectors"
      :loading="loading"
      :footer-props="{
        'showFirstLastPage':true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >
      <template v-slot:item.sectorType="{ item }">
        <v-chip
            class="ma-2"
            color="blue darken-1"
            text-color="white"
            v-if="item.sectorType === 'CATEGORY_SECTOR'"
        >
          CATEGORY SECTOR
        </v-chip>
        <v-chip
            class="ma-2"
            color="deep-purple accent-1"
            text-color="white"
            v-if="item.sectorType === 'DONATION'"
        >
          DONATION
        </v-chip>
      </template>
      <template v-slot:item.start="{ item }">
        {{ parseDate(item.start) }}
      </template>
      <template v-slot:item.end="{ item }">
        {{ parseDate(item.end) }}
      </template>
      <template v-slot:item.donationBanner="{ item }">
        <a v-bind:href="item.donationBanner" target="_blank">
          <v-img
              v-if="
              item != null &&
                item.donationBanner != null
            "
              :src="item.donationBanner"
              contain
              max-height="25"
              max-width="25"
          >
          </v-img>
        </a>
      </template>
      <template v-slot:item.iconActivePng="{ item }">
        <a v-bind:href="item.iconActivePng" target="_blank">
            <v-img
                v-if="
              item != null &&
                item.iconActivePng != null
            "
                :src="item.iconActivePng"
                contain
                max-height="25"
                max-width="25"
            >
            </v-img>
        </a>
      </template>

      <template v-slot:item.iconNormalSvg="{ item }">
        <a v-bind:href="item.iconNormalSvg" target="_blank">
          <v-img
              v-if="
              item != null &&
                item.iconNormalSvg != null
            "
              :src="item.iconNormalSvg"
              contain
              max-height="25"
              max-width="25"
          >
          </v-img>
        </a>
      </template>

      <template v-slot:item.iconActiveSvg="{ item }">
        <a v-bind:href="item.iconActiveSvg" target="_blank">
          <v-img
              v-if="
              item != null &&
                item.iconActiveSvg != null
            "
              :src="item.iconActiveSvg"
              contain
              max-height="25"
              max-width="25"
          >
          </v-img>
        </a>
      </template>
      <template v-slot:item.active="{ item }">
        <v-chip
            class="ma-2"
            color="green"
            text-color="white"
            v-if="item.active"
        >
          {{$t("common.activated")}}
        </v-chip>
        <v-chip
            class="ma-2"
            color="red"
            text-color="white"
            v-if="!item.active"
        >
          {{$t("common.deactivated")}}
        </v-chip>
      </template>

      <template v-slot:item.image="{ item }">
        <v-img
            max-height="25"
            max-width="25"
          contain
          :src="item.imageFilePath"
        ></v-img>
      </template>
      <template v-slot:item.shops="{ item }">
        {{ item.shops.length > 0 ? item.shops[0].name : "" }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-if="item.shops.length > 1" small class="mr-2" v-on="on"
              >mdi-dots-horizontal</v-icon
            >
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item v-for="(item, i) in item.shops" :key="i">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.clear="{ item }">
        <v-avatar color="teal" size="26" rounded>
          <router-link
            :to="{
              name: 'SectorEdit',
              params: { sectorId: item.id }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.add="{ item }">
        <v-avatar color="red darken-4" size="26" rounded>
          <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-text-field v-model="sectorName" type="text" @keyup.enter="page = 1;retrieveSectors();"></v-text-field>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  retrieveSectors();
                "
                >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  clearSearch();
                "
                >mdi-close
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <router-link :to="{ name: 'SectorCreate' }">
              <v-avatar color="teal" size="26" rounded>
                <v-icon dark>mdi-plus</v-icon>
              </v-avatar>
            </router-link>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: "sectors",
  computed: {
    ...mapGetters(["currentUser"]),
    headers(){
      return [
        { text: "Id", align: "start", sortable: true, value: "id" },
        { text: "Sector Name", value: "name", width: "10%", sortable: false },
        { text: this.$i18n.t('pages.shopCategories.iconNormalPNG'), value: "image", sortable: false },
        { text: this.$i18n.t('pages.shopCategories.iconActivePNG'), sortable: false },
        { text: this.$i18n.t('pages.shopCategories.iconNormalSVG'), sortable: false },
        { text: this.$i18n.t('pages.shopCategories.iconActiveSVG'), value: "iconActiveSvg", sortable: false },
        { text: this.$i18n.t('commonTable.city'), value: "cityName", sortable: false },
        { text: this.$i18n.t('pages.sectors.shops'), value: "shops", width: "10%", sortable: false },
        { text: this.$i18n.t('pages.sectors.type'), value: "sectorType", sortable: false },
        { text: this.$i18n.t('Url'), value: "url", sortable: false },
        { text: this.$i18n.t('date.from'), value: "start", sortable: false },
        { text: this.$i18n.t('date.to'), value: "end", sortable: false },
        { text: this.$i18n.t('pages.banners.banner'), value: "donationBanner", sortable: false },
        { text: this.$i18n.t('pages.sectors.active'), value: "active", width: "10%", sortable: false },
        { text: "", value: "search", width: "5%", sortable: false },
        { text: "", value: "clear", width: "5%", sortable: false },
        { text: "", value: "add", width: "5%", sortable: false }
      ]
    }
  },
  data() {
    return {
      sectorName: "",
      postPublished: "",
      totalSectors: 0,
      sectors: [],
      sector: {
        name: "",
        description: "",
        shops: [],
        active: false
      },
      defaultSector: {
        name: "",
        description: "",
        shops: [],
        active: false
      },
      loading: true,
      pagination: {},
      showEditSectorDialog: false,
      shopList: [],
      selectedShops: [],
      cityId: null,
      dialogDelete: false,
      itemToDelete: null,
      mediaList: [],
      errors: []
    };
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveSectors();
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Sectors" }]);
  },
  components: {
    ConfirmDialog
  },
  methods: {
    parseDate(date) {
      if (date == null) return "";
      return moment
          .utc(date)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
    },
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
                this.$i18n.t("pages.sectors.deleteSectorMessage") + item.id + " ?"
        )
      ) {
        this.deleteRecord(item);
      }
    },
    deleteRecord(item) {
      this.deleteSector(item.id);
    },
    updateSelectedShops(selectedShops) {
      this.selectedShops = selectedShops;
    },
    updateSelectedCity(selectedCity) {
      this.cityId = selectedCity;
    },
    deleteSector(sectorId) {
      this.loading = true;
      ApiService.delete(`api/sectors/${sectorId}`)
        .then(response => {
          this.$log.debug("Sector deleted: ", response);
          this.retrieveSectors();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    emptySector() {
      this.defaultSector.name = "";
      this.defaultSector.description = "";
      this.defaultSector.shops = [];
      this.defaultSector.categoryId = null;
      this.cityId = null;
      this.selectedShops = [];
    },
    gotToEditSectorDialog(item) {
      if (item != null) {
        ApiService.get(`api/sectors`, `${item.id}`)
          .then(response => {
            this.$log.debug("Sector: ", response.data);
            this.shopsList = []; //get all shops
            this.sector = item;
            this.cityId = response.data.cityId;
            this.selectedShops = response.data.shops.map(function(shop) {
              return shop.id;
            });
            this.$log.debug("selectedShops: ", this.selectedShops);
            this.$log.debug("selectedCityId: ", this.cityId);
            this.showEditSectorDialog = true;
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      } else {
        this.emptySector();
        this.$log.debug("New Sector: ", this.defaultSector);
        this.sector = this.defaultSector;
        this.shopsList = []; //get all shops
        this.showEditSectorDialog = true;
      }
    },
    getRequestParams(sectorName) {
      let params = {};

      if (sectorName) {
        params["sectorName"] = sectorName;
      }

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },
    clearSearch() {
      this.sectorName = "";
    },

    retrieveSectors() {
      const params = this.getRequestParams(this.sectorName);
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/sectors", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Sectors: ", response.data.content);
          this.sectors = response.data.content;
          this.totalSectors = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalSectors != null ? this.totalSectors : 0;
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Sectors: " + title }
          ]);
          resolve();
        });
      });
    }
  }
};
</script>

<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}
.v-icon.v-icon {
  font-size: 24px !important;
}
tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>
