var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser.superAdmin)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sectors,"options":_vm.pagination,"server-items-length":_vm.totalSectors,"loading":_vm.loading,"footer-props":{
      'showFirstLastPage':true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.sectorType",fn:function(ref){
    var item = ref.item;
return [(item.sectorType === 'CATEGORY_SECTOR')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"blue darken-1","text-color":"white"}},[_vm._v(" CATEGORY SECTOR ")]):_vm._e(),(item.sectorType === 'DONATION')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"deep-purple accent-1","text-color":"white"}},[_vm._v(" DONATION ")]):_vm._e()]}},{key:"item.start",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.start))+" ")]}},{key:"item.end",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.end))+" ")]}},{key:"item.donationBanner",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":item.donationBanner,"target":"_blank"}},[(
            item != null &&
              item.donationBanner != null
          )?_c('v-img',{attrs:{"src":item.donationBanner,"contain":"","max-height":"25","max-width":"25"}}):_vm._e()],1)]}},{key:"item.iconActivePng",fn:function(ref){
          var item = ref.item;
return [_c('a',{attrs:{"href":item.iconActivePng,"target":"_blank"}},[(
            item != null &&
              item.iconActivePng != null
          )?_c('v-img',{attrs:{"src":item.iconActivePng,"contain":"","max-height":"25","max-width":"25"}}):_vm._e()],1)]}},{key:"item.iconNormalSvg",fn:function(ref){
          var item = ref.item;
return [_c('a',{attrs:{"href":item.iconNormalSvg,"target":"_blank"}},[(
            item != null &&
              item.iconNormalSvg != null
          )?_c('v-img',{attrs:{"src":item.iconNormalSvg,"contain":"","max-height":"25","max-width":"25"}}):_vm._e()],1)]}},{key:"item.iconActiveSvg",fn:function(ref){
          var item = ref.item;
return [_c('a',{attrs:{"href":item.iconActiveSvg,"target":"_blank"}},[(
            item != null &&
              item.iconActiveSvg != null
          )?_c('v-img',{attrs:{"src":item.iconActiveSvg,"contain":"","max-height":"25","max-width":"25"}}):_vm._e()],1)]}},{key:"item.active",fn:function(ref){
          var item = ref.item;
return [(item.active)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("common.activated"))+" ")]):_vm._e(),(!item.active)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("common.deactivated"))+" ")]):_vm._e()]}},{key:"item.image",fn:function(ref){
          var item = ref.item;
return [_c('v-img',{attrs:{"max-height":"25","max-width":"25","contain":"","src":item.imageFilePath}})]}},{key:"item.shops",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.shops.length > 0 ? item.shops[0].name : "")+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(item.shops.length > 1)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal")]):_vm._e()]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((item.shops),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)]}},{key:"item.clear",fn:function(ref){
          var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('router-link',{attrs:{"to":{
            name: 'SectorEdit',
            params: { sectorId: item.id }
          }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)],1)]}},{key:"item.add",fn:function(ref){
          var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"red darken-4","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.delRecord(item)}}},[_vm._v("mdi-delete")])],1)]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-spacer')],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;_vm.retrieveSectors();}},model:{value:(_vm.sectorName),callback:function ($$v) {_vm.sectorName=$$v},expression:"sectorName"}})],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.retrieveSectors();}}},[_vm._v("mdi-magnify ")])],1)],1),_c('td',[_c('v-avatar',{attrs:{"color":"grey","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.clearSearch();}}},[_vm._v("mdi-close ")])],1)],1),_c('td',[_c('router-link',{attrs:{"to":{ name: 'SectorCreate' }}},[_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)],1)])]},proxy:true}],null,false,1058964131)}),_c('ConfirmDialog',{ref:"confirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }